<template>
  <PageContent class="commande">
    <template #header-right>
      <ButtonClassic
        :label="$t('filtre.filtres')"
        color="primary"
        :variant="showFilterSidebar ? 'solid' : 'ghost'"
        :class="{ active: showFilterSidebar, }"
        :disabled="isLoading"
        icon="right"
        @click="showFilterSidebar = !showFilterSidebar"
      >
        <template #right-icon>
          <UilFilter v-if="!showFilterSidebar" />
          <UilTimes v-else />
        </template>
      </ButtonClassic>
      <ButtonGroup>
        <ButtonClassic
          balise-type="button"
          variant="solid"
          icon="right"
          :disabled="!selections.length"
          @click="downloadPdf('commande')"
        >
          <template #right-icon>
            <IconPDF class="icon-medium" />
          </template>
        </ButtonClassic>
        <ButtonClassic
          variant="solid"
          icon="right"
          @click="copyToClipboard(`${currentUrl}?id_organisme=${organismeActuel.id}`)"
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
      <ContextMenu
        :selections="selections"
        :bloc-actions-globales="actionsGlobales"
        :bloc-actions-selections="actionsSelections"
        @click-action="handleActions($event)"
      />
    </template>

    <template
      v-if="!isLoading"
      #action-bar
    >
      <FiltersActionBar
        v-if="crossFilters?.length"
        :active-filters="crossFilters"
        :total-results="totalRows"
        @delete-tag="uncheckCrossFilter($event)"
        @reset-filters="resetCrossFilters()"
      />
      <InfosDotation
        v-else-if="budget.dotation > 0 && (isHorsMarche || !hasPerm('can_view_as_maitre_compta'))"
        :budget="budget"
        :budget-type="budgetType"
      />
    </template>

    <template #aside-content>
      <FilterSidebar
        v-if="showFilterSidebar && allFilters.length"
        :title="$t('recherche.affiner-votre-recherche')"
        :possible-filters="allFilters"
        :active-filters="activeFilters"
        @update="fetch()"
      />
    </template>

    <template
      v-if="!isLoading"
      #content
    >
      <ErrorPage
        v-if="noResults"
        variant="no-results"
      />
      <template v-else-if="!emptyData">
        <DevisCommandesMaitreComptaTable
          v-if="hasPerm('can_view_as_maitre_compta')"
          is-commande
          :rows="rows"
          :sorting="sort"
          :sorting-children="sortChildren"
          :children-are-loading="subArrayIsLoading"
          :budget-type="budgetType"
          :sub-page-size="subPageSize"
          @sort="handleSort($event)"
          @sort-children="handleSortChildren($event)"
        />
        <DevisCommandesTable
          v-else
          :rows="rows"
          :active-sort="sort"
          is-commande
          @sort="handleSort($event)"
        />
        <div
          v-if="totalRows > pageSize"
          class="pagination-container"
        >
          <Pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="pageSize"
            @change="(page) => $router.push({ query: { ...$route.query, page, }, })"
          />
        </div>
      </template>
      <div
        v-else
        class="empty-page"
      >
        <h2 class="s4 light">
          {{ $t('commande.aucune-commande') }}
        </h2>
        <img
          :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_cart.svg')"
          :alt="$t('general.alt-image-illustration')"
        />
      </div>
      <ModalListesSelection
        id="modal_listes_selection"
        :listes="activeLists"
        @open-creation-liste="$modal.show('modal_listes_creation')"
      />
    </template>
    <template
      v-else
      #content
    >
      <div>
        <InfiniteLoader />
      </div>
    </template>
  </PageContent>
</template>

<script>
import { openInNewTab, copyToClipboard } from "@lde/core_lde_vue";

import DevisCommandes from "@/mixins/mixinDevisCommandes";
import DownloadMultiPdf from "@/mixins/mixinDownloadMultiPdf";
import FiltersActionBar from "@/components/search/filters/FiltersActionBar.vue";
import SearchFilters from "@/mixins/mixinSearchFilters";
import ModalListesSelection from "@/components/modals/ModalListesSelection.vue";

import Api from "@/modules/axios";

import { UilFilePlusAlt } from "@iconscout/vue-unicons";

import config from "@/config";

import { mapGetters } from "vuex";

/**
 * Vue de la liste des commandes.
 */
export default {
  name: "Commande",
  components: {
    FiltersActionBar,
    ModalListesSelection,
  },
  mixins: [DevisCommandes, DownloadMultiPdf, SearchFilters],
  data() {
    return {
      currentUrl: window.location.href,
      showFilterSidebar: false,
    };
  },
  computed: {
    ...mapGetters(["isHorsMarche", "activeLists"]),
    selections() {
      if (this.hasPerm("can_view_as_maitre_compta")) {
        let checkedCommande = [];
        this.rows.forEach((org) => {
          checkedCommande = [...checkedCommande, ...org._children.filter((commande) => commande._checked)];
        });
        return checkedCommande;
      }
      return this.rows.filter((commande) => commande._checked);
    },
    actionsSelections() {
      return [
        {
          slug: "add_to_list",
          label: this.$t("action.ajouter-references-liste"),
          icon: UilFilePlusAlt,
          disabled: this.isImpersonating || !this.selections.length || this.selections.some((s) => s.verrouille),
        },
        {
          slug: "add_to_panier",
          label: this.$t("action.ajouter-references-panier"),
          icon: UilFilePlusAlt,
          disabled: this.isImpersonating || !this.selections.length || this.selections.some((s) => s.verrouille),
        },
      ];
    },
    crossFilters() {
      // Filtres venant d'autres vues. Utilisés pour trouver les factures, commandes et expéditions liées.
      const res = [];

      const expe = this.$route.query.expedition;
      if (expe) {
        res.push({
          libelle: `Expédition #${expe}`,
          id: expe,
          key: "expedition",
        });
      }

      const fact = this.$route.query.facture;
      if (fact) {
        res.push({
          libelle: `Facture #${fact}`,
          id: fact,
          key: "facture",
        });
      }

      return res;
    },
  },
  mounted() {
    this.fetchBudget(this.hasPerm("can_view_as_maitre_compta"))
      .finally(() => {
        this.fetch();
      });
  },
  methods: {
    copyToClipboard,
    openInNewTab,
    /**
     * Appelle la bonne méthode de fetchCommande en fonction des permissions (région ou non).
     */
    fetch() {
      this.isLoading = true;
      this.showFilterSidebar = false;

      if (this.hasPerm("can_view_as_maitre_compta")) {
        this.fetchCommandeRegion();
      } else {
        this.fetchCommandeEtab();
      }
    },
    /**
     * Récupère les commandes de l'établissement courant (prescripteur et valideur).
     */
    fetchCommandeEtab() {
      this.isLoading = true;

      const crossFilterKeys = this.crossFilters.map(({ key }) => key);
      const filterQuery = this.activeFilters.map(
        ({ slug, options }) => (
          crossFilterKeys.includes(slug) ? [`${slug}__in`, options.join(",")] : [`${slug}__in`, options.join(",")]
        ),
      );

      const params = {
        page_size: this.pageSize,
        page: this.currentPage,
        organisme: this.organismeActuel.id,
        ...Object.fromEntries(filterQuery),
      };

      if (this.sort.key) {
        params.ordering = `${this.sort.sortAscended ? "" : "-"}${this.sort.key}`;
      }

      Api().get("/commande/", { params })
        .then(({ data: { results, count, filtres } }) => {
          this.allFilters = filtres;
          this.rows = results.map((commande) => ({
            id: commande.id,
            libelle: commande.libelle,
            id_4d: commande.id_4d,
            num_engagement: commande.num_engagement,
            date: commande.date,
            total_quantite: commande.total_quantite,
            nb_expediee: commande.nb_expediee,
            nb_livree: commande.nb_livree,
            has_num: commande.has_num,
            statut_affiche: commande.statut_affiche,
            total_ttc: commande.total_ttc,
            total_ht: commande.total_ht,
            lignes: commande.lignes,
            _routeName: "commandes_factures_commandes_item",
          }));
          this.totalRows = count;
          this.isLoading = false;
        });
    },
    /**
     * Récupère les établissements (avec commande) ayant des commandes à valider (donneur d'ordres/région).
     */
    fetchCommandeRegion() {
      this.isLoading = true;

      const crossFilterKeys = this.crossFilters.map(({ key }) => key);
      const filterQuery = this.activeFilters.map(
        ({ slug, options }) => (
          crossFilterKeys.includes(slug) ? [slug, options.join(",")] : [`${slug}__in`, options.join(",")]
        ),
      );

      const params = {
        page_size: this.pageSize,
        page: this.currentPage,
        ...Object.fromEntries(filterQuery),
      };

      if (this.sort.key) {
        params.ordering = `${this.sort.sortAscended ? "" : "-"}${this.sort.key}`;
      }

      const { budgetsOrganismes } = this.budget;

      Api().get("/organisme/maitre_compta_commande/", { params })
        .then(({ data: { results: organismes, filtres = [], count: totalRows } }) => {
          this.totalRows = totalRows;
          this.noResults = filterQuery.length && !organismes.length;
          this.allFilters = filtres;
          if (organismes.length) {
            this.rows = organismes.map((org, idx) => {
              const commandes = org.commande.results;
              const budget = budgetsOrganismes && budgetsOrganismes[org.id_organisme.toUpperCase()];

              return {
                tmp_count: idx,
                id: org.id,
                libelle: org.nom_complet,
                uai: org.uai,
                budget: budget ? this.formalizeBudget(budget) : {},
                total_ttc: org.total_ttc,
                total_ht: org.total_ht,
                counter: org.commande.count,
                currentPage: 1,
                isLoading: false,
                _children: commandes.map((commande) => ({
                  id: commande.id,
                  libelle: commande.libelle,
                  id_4d: commande.id_4d,
                  num_engagement: commande.num_engagement,
                  date: commande.date,
                  verrouille: commande.verrouille,
                  total_quantite: commande.total_quantite,
                  nb_livree: commande.nb_livree,
                  nb_expediee: commande.nb_expediee,
                  statut_affiche: commande.statut_affiche,
                  total_ttc: commande.total_ttc,
                  total_ht: commande.total_ht,
                  lignes: commande.lignes,
                  _routeName: "commandes_factures_commandes_item",
                  _variant: commande.statut_affiche === "expire" ? "dashed" : undefined,
                })),
              };
            });
            this.isLoading = false;
          }
        });
    },
    /**
     * Récupère les commandes d'un établissement.
     * @param {Number} idOrganisme ID de l'organisme.
     * @param {Number} page Numéro de page à afficher.
     * @param {Object} sort Clé et sens de tri.
     * @returns {Promise} Devis d'un organisme selon la page et le tri.
     */
    fetchChildren(idOrganisme, sort, page) {
      const filterQuery = this.activeFilters
        .map(({ slug, options }) => [`${slug}__in`, options.join(",")])
        .filter((f) => f[0] !== "id__in"); // On retire les organismes (on n'en veut qu'un)

      const params = {
        page_size: this.subPageSize,
        page,
        organisme: idOrganisme,
        ...Object.fromEntries(filterQuery),
      };

      if (sort?.key) {
        this.sortChildren.key = sort.key;
        this.sortChildren.sortAscended = sort.sortAscended;
      }

      if (this.sortChildren?.key) {
        params.ordering = `${this.sortChildren.sortAscended ? "" : "-"}${this.sortChildren.key}`;
      }

      return Api().get("/commande/", { params })
        .then((res) => res.data.results.map(
          (commande) => ({
            id: commande.id,
            libelle: commande.libelle,
            id_4d: commande.id_4d,
            date: commande.date,
            quantite: commande.total_quantite,
            nb_livree: commande.nb_livree,
            nb_expediee: commande.nb_expediee,
            statut_affiche: commande.statut_affiche,
            total_ttc: commande.total_ttc,
            total_ht: commande.total_ht,
            lignes: commande.lignes,
          }),
        ));
    },
    /**
     * Callback des actions du menu contextuel.
     * @param {Object} action Objet definissant l'action avec son slug, son label...
     */
    handleActions(action) {
      switch (action.slug) {
        case "ask_export_xls":
          this.$store.dispatch("askExportStatsMarche");
          break;
        case "export_xls":
          this.exportXls("commande");
          break;
        case "add_to_panier":
          this.$store.dispatch("addOrderLinesToPanier", { commandes: this.selections.map((s) => s.id) });
          break;
        case "add_to_list":
          this.$store.commit("setSelectedLines", this.selections.map((s) => s.id));
          this.$modal.show("modal_listes_selection");
          break;
        case "restant_livrer":
          this.openInNewTab(`${config.api.baseUrl}/commande/restant_livrer_pdf/`);
          break;
        default:
          break;
      }
    },
    /**
     * Réinitialise les filtres et met la paginsation sur 1.
     */
    resetCrossFilters() {
      this.$router.push({ name: this.$route.name });
      this.currentPage = 1;
    },
    /**
     * Enlève le filtre et rafraîchit les résultats.
     * @param {Object} filter Filtre à supprimer.
     */
    uncheckCrossFilter(filter) {
      this.$router.push({
        name: this.$route.name,
        ...{ ...this.$route.query, [filter.key]: undefined },
      });
    },
  },
};
</script>

<style lang="scss"></style>
